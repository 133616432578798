import { ObjectUtil } from "src/app/core/utility/object.util";

export class LogListItemModel {
    public rowNumber: number;
    public message: string;
    public logType: string;
    public timeStamp: string;
    public level: string;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
