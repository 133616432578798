import { ObjectUtil } from "../../utility/object.util";

export class OurGoalModel {
    public rowNumber: number;
    public id: number;
    public year: number;
    public goal: number;
    public current: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}