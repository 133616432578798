import { ObjectUtil } from "../utility/object.util";

export class MyGoalModel {
    public rowNumber: number;
    public id: number;
    public userId: number;
    public userName: string;
    public year: number;
    public goal: number;
    public current: number;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}